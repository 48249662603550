<template>
	<div id="cpm_orderDetail" class="el-content">
		<div class="order-steps">
			<a-steps :current="active" status="finish">
			    <a-step title="买家下单" :description="detail.create_time" />
			    <a-step title="买家付款" :description="detail.pay_time" />
			    <a-step title="商家发货" :description="detail.send_time" />
			    <a-step title="订单完成" :description="detail.confirm_time" />
			</a-steps>
		</div>
		<a-row class="order-info">
			<a-col  :span="12" style="border-right: 1px solid #f4f4f4;padding: 20px;">
				<div class=" bg-purple">
					<div class="self-order" v-if="detail.recovery_method==2">自提订单</div>
					<div class="order-li">
						<div class="order-title">订单编号 :</div><div>{{detail.order_number}}</div>	
					</div>
					<div class="order-li">
						<div class="order-title">商户订单号 :</div><div>{{detail.pay_order_number}}</div>	
					</div>
					<div class="order-li">
						<div class="order-title">付款方式 :</div><div>{{$util.payMethod(detail.pay_method)}}</div>	
					</div>
					<div class="order-li">
						<div class="order-title">买家 :</div>
						<div v-if="detail.user">
							<a-tooltip>
								<template #title>
									<div style="display: flex;align-items: center;">头像：
										<a-avatar :src="detail.user.avatar_url"/>
									</div>
									<div>昵称：{{detail.user.nickname}}</div>
									<div>真实姓名：{{detail.user.true_name}}</div>
									<div>使用金额：{{detail.user.use_money}}</div>
									<div>支付订单数：{{detail.user.pay_order_number}}</div>
								</template>
								<a-tag style="cursor: pointer;" color="#00CC66">{{detail.user.nickname}}</a-tag>
							</a-tooltip>
						</div>	
					</div>
					<div class="order-li">
						<div class="order-title">一级分销佣金 :</div><div>￥{{detail.one_price}}</div>	
					</div>
					<div class="order-li">
						<div class="order-title">二级分销佣金:</div><div>￥{{detail.two_price}}</div>	
					</div>
					<div class="order-li" v-if="detail.recovery_method==1"> 
						<div class="order-title">收货信息 :</div>
						<div>
							<span>{{detail.name}}/{{detail.mobile}} {{detail.address}}</span>
							<!-- <a-button type="link" v-clipboard:copy="detail.name+' '+detail.mobile+' '+ detail.address"  v-clipboard:success="onCopy"  slot="append" icon="el-icon-document-copy" size="small"></a-button> -->
						</div>
					</div>
					<div class="order-li" v-if="detail.outlet"> 
						<div class="order-title">配送门店 :</div>
						<div>
							<div>门店名称：{{detail.outlet.name}}</div>
							<div>门店电话：{{detail.outlet.mobile}}</div>
							<div>门店地址：{{detail.outlet.address}}</div>
						</div>
					</div>
					
					<div class="order-li" v-if="detail.recovery_method==1">
						<div class="order-title">物流信息： </div>
						<div>
							<div v-if="detail.express_company || detail.express_no" style="display: flex;align-items: center;">
								<span>快递公司 : {{detail.express_company}} {{detail.express_no}}</span>
									
								<!-- 未收货前可修改物流信息 -->
								<com-update-express 
									title="[修改物流信息]"
									v-if="$status.getShopOrderBtnAuth(detail,'confirm')" 
									:order-id="detail.id" 
									:type="1" 
									@success="getOrderDetail">
								</com-update-express>
							</div>
							
							<template v-if="detail.runing">
								<template v-if="detail.runing.run_type == 'dada'">
									<div>配送员：{{detail.runing.dm_name}}</div>
									<div>配送员电话：{{detail.runing.dm_mobile}}</div>
								</template>
								<template v-else-if="detail.runing.persion">
									<div>配送员：{{detail.runing.persion.name}}</div>
									<div>配送员电话：{{detail.runing.persion.mobile}}</div>
								</template>
								
								<div>取货时间：{{detail.runing.pickup_time || '--'}}</div>
								<div>送达时间：{{detail.runing.confirm_time || '--'}}</div>
								<div v-if="detail.runing.order_status == '5'">状态：已取消（{{detail.runing.cancel_reason || '--'}}）</div>
								<!-- <com-delivery-cancel v-if="detail.runing.order_status == '1'" :order="detail" :type="1" :send="detail.runing.run_type" @change="getOrderDetail"></com-delivery-cancel> -->
							</template>
						</div>	
					</div>
					
					<div v-if="detail.shopping_card">
						<div class="order-li">
							<div class="order-title">购物卡单号:</div><div>{{detail.shopping_card.card.number}} </div>	
						</div>
						<div class="order-li">
							<div class="order-title">购物卡id:</div><div>{{detail.shopping_card.cid}} </div>
						</div>
						<div class="order-li" v-if="detail.card_package">
							<div class="order-title">包装信息:</div><div>{{detail.card_package.name}} </div>	
						</div>
						<div class="order-li" v-if="detail.card_package">
							<div class="order-title">包装价格:</div><div>￥{{detail.card_package.price}} </div>	
						</div>
					</div>
					
					<div  class="order-li" v-has="{action:'wxapp_order_update_address',plat:isShop}" v-if="detail.recovery_method==1">
						<div class="order-title"></div>
						<div>
							<com-update-address btn-type="primary" :type="1" :orderid="detail.id" @change="getOrderDetail"></com-update-address>
						</div>	
					</div>
					
					<!-- 自提订单查看门店信息 -->
					<div v-if="detail.recovery_method==2">
						<div class="order-li">
							<div class="order-title">自提人:</div><div>{{detail.name}} ( 电话：{{detail.mobile}} )</div>	
						</div>
						<div class="order-li">
							<div class="order-title">门店名称:</div><div>{{detail.outlet.name}} ( 电话：{{detail.outlet.mobile}} )</div>	
						</div>
						<div class="order-li">
							<div class="order-title">门店地址:</div><div>{{detail.outlet.address}}</div>	
						</div>
						<div class="order-li" v-has="{action:'wxapp_order_writeOff'}">
							<div class="order-title">提货码:</div>
							<div style="font-size: 24px;">
								<span>{{detail.offline_code}}</span>
								<i class="iconfont icon-qrcode" @click="checkDialog=true"></i>
								<span style="color: rgb(243, 71, 71);font-size: 16px;" v-if="detail.order_status == 3">已核销</span>
							</div>	
						</div>
						<div class="order-li" v-if="detail.offline_user">
							<div class="order-title">核销信息：</div>	
							<div>{{detail.offline_user}}</div>	
						</div>
					</div>
				</div>
			</a-col>
			
			<a-col :span="12" style="padding: 20px;">
				<div class=" bg-purple-light">
					<div class="order-li" >
						<div class="order-title">订单备注 :</div>
						<div>
							<span v-if="detail.remark && detail.remark !='undefined'"> {{detail.remark}} </span>
						</div>	
					</div>
					<div class="order-li" >
						<div class="order-title">商家备注 :</div>
						<div>
							<span> {{detail.manager_remark}} </span>
						</div>	
					</div>
					<div class="order-li" >
						<div class="order-title">订单状态 :</div>
						<div style="display: flex;align-items: center;">
							<span class="order-status" style="margin-right: 4px;">{{$status.getShopOrderStaus(detail)}} </span>
							<span v-if="detail.order_status==1 && (detail.is_refund==0 || detail.is_refund == null )">（买家已经付款，请商家尽快发货）</span>
							<template v-if="detail.order_status == 1 && detail.is_refund== 1">
								<com-order-refund :orderid="detail.id" @change="getOrderDetail"></com-order-refund>
							</template>
						</div>	
					</div>
					<div class="order-li" v-if="isShop==0 && detail.id">
						<com-cashback-status :order-id="detail.id" :order-type="1"></com-cashback-status>
					</div>
					<div class="order-li" style="margin: 30px 0 10px 0;align-items: center;">
						<span v-if="$status.getShopOrderBtnAuth(detail,'delivery')" v-has="{action:'wxapp_order_send',plat:isShop}">
							<com-deliver-goods
								btn-type="link"
								btn-size="small" 
								btn-text="[发货]"
								:id="detail.id" 
								:type="1" 
								:is-shop="isShop" 
								@success="()=>{getOrderDetail()}">
							</com-deliver-goods>
						</span>
						<a-button type="link" size="small" 
							v-if="$status.getShopOrderBtnAuth(detail,'confirm') && isShop == 0" 
							v-has="{action:'wxapp_order_confirm',plat:isShop}"
							@click="handleOrder('3','确认该订单已经收货了吗？')">[确认收货]
						</a-button>
						<span  v-has="{action:'wxapp_order_remark',plat:isShop}">
							<com-set-remark 
								btn-text="[备注]" 
								btn-type="link"
								btn-size="small" 
								remark-type="goods-order"
								postApi="doSomethingByOrder" 
								:remarkValue="detail.manager_remark"
								:params="{
									pk:detail.id,
									type:5 ,
									order_type:1
								}" @success="()=>{getOrderDetail()}">
							</com-set-remark>
						</span>
						<span 
							v-has="{action:'wxapp_order_logistics',plat:isShop}" 
							v-if="$status.getShopOrderBtnAuth(detail,'logistics')">
							<com-logistics :order-type="1" :pk="detail.id" btn-type="link" btn-text="[查看物流]"></com-logistics>
						</span>
						<a-button type="link" v-has="{action:'wxapp_order_print',plat:isShop}" @click="printOrder">[打印订单]</a-button>
						<a-button type="link" 
							v-has="{action:'wxapp_order_refund',plat:isShop}" 
							@click="handleOrder('refund','确认要对该订单进行退款操作吗？')"  
							v-if="$status.getShopOrderBtnAuth(detail,'refund')">[退款]
						</a-button>
						<com-pay-result :id="detail.id" :type="1"></com-pay-result>
					</div>
					<div class="order-tag">友情提示： 如果无法进行发货，请及时联系买家进行妥善处理</div>
				</div>
			</a-col>
		</a-row>
		
		<div class="goods-info">
			<h3>商品信息</h3>
			<a-row class="g-info-header">
				<a-col :span="4"><div class=" bg-purple">商品信息</div></a-col>
				<a-col :span="4" style="text-align: center;"><div class="goods-info_title">规格、编码</div></a-col>
				<a-col :span="4" style="text-align: center;"><div class="goods-info_title">单价</div></a-col>
				<a-col :span="4" style="text-align: center;"><div class="goods-info_title">数量</div></a-col>
				<a-col :span="4" style="text-align: center;"><div class="goods-info_title">原价</div></a-col>
				<a-col :span="4" style="text-align: center;"><div class="goods-info_title">折扣后</div></a-col>
			</a-row>
			<div class="goods-li">
				<a-row v-for="(item,index) in detail.detail" :key="index">
					<a-col :span="4" style="border-right: 1px solid #F4F4F4;">
						<div class=" bg-purple" style="display: flex;">
							<img class="th-cover" :src="item.snap_goods.cover" alt="">
							<div class="goods-name">{{item.goods_name}}</div>
						</div>
					</a-col>
					<a-col :span="4" class="li-info">
						<div class=" bg-purple-light" v-if="item.snap_goods_spec">
							<span v-for="(val,ind) in item.snap_goods_spec.sku" :key="ind">{{val.spec_value}} ;</span>
						</div>
						<div class=" bg-purple-light" v-else>无</div>
					</a-col>
					<a-col :span="4" class="li-info"><div class=" bg-purple">￥{{item.price}}</div></a-col>
					<a-col :span="4" class="li-info"><div class=" bg-purple-light">x {{item.count}}</div></a-col>
					<a-col :span="4" class="li-info"><div class=" bg-purple">￥{{item.snap_goods.old_price}}</div></a-col>
					<a-col :span="4" class="li-info"><div class=" bg-purple-light">￥{{item.price}}</div></a-col>
				</a-row>
				<a-row>
				  <a-col :span="24" class="total-info">
						<div class=" bg-purple-dark" style="width: 98%;">
							<p>
								<span class="price-inner">商品小计 : </span>
								<span style="font-weight: bold;">￥{{detail.subtotal}}</span>
							</p>
							<p>
								<span class="price-inner">运费 : </span>
								<span >{{detail.send_price}}元</span>
							</p>
							<p>
								<span class="price-inner">折扣 : </span>
								<span style="color: orange;">-{{detail.discount}}元</span>
							</p>
							<p>
								<span class="price-inner">商家优惠 : </span>
								<span style="color: orange;">-{{detail.manager_discount}}元</span>
							</p>
							<p>
								<span class="price-inner">优惠券 : </span>
								<span style="color: orange;">-{{detail.coupon_price}}元</span>
							</p>
							<p>
								<span class="price-inner">实付款 : </span>
								<span style="font-weight: bold;color: orangered;">{{detail.true_price}}元</span>
							</p>
						</div>
				  </a-col>
				</a-row>
			</div>
		</div>
	</div>

</template>

<script>
import comLogistics from '@/components/miniapp/com-logistics.vue'
import comPayResult from '@/components/miniapp/com-pay-result.vue'
import comUpdateAddress from '@/components/miniapp/com-update-address.vue'
import comUpdateExpress from '@/components/miniapp/com-update-express.vue'
import comSetRemark from '@/components/miniapp/com-set-remark.vue'
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import comOrderRefund from '@/components/miniapp/com-order-refund.vue'
import comCashbackStatus from '@/components/miniapp/com-cashback-status.vue'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import goodsModel from '@/api/goods.js'
import common from '@/api/common.js'
export default{
	name:'page-order-detail',
	components:{
		comSetRemark,
		comDeliverGoods,
		comPrintFaceOrder,
		comOrderRefund,
		comLogistics,
		comPayResult,
		comUpdateAddress,
		comUpdateExpress,
		comCashbackStatus
	},
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	// mounted() {
	// 	let t = this ,{ id } = t.$route.query
	// 	this.id = parseInt(id ) 
	// 	t.getOrderDetail();
	// },

	setup(){
		const _d = reactive({
			id:0,
			active:1,
			checkDialog:false,
			updateAddressDialog:false,
			detail:{
				user:{nickname:''},
				order_status_show:{txt:'',code:''},
				card_package:null,
				shopping_card:null
			},
			is_update_address:0,
			manager_remark:'',
			deliveryForm:{
				name:'',
				phone:'',
				address:'',
				express_no:'',
				express_company:'',
			},
			address:{
				name:'',
				mobile:'',
				address:'',
				latitude:'',
				longitude:'',
			},
			copy_text:'',
			show:{
				delivery:false,
				reback:false,
				deliveryCancel:false,
			},
			deliveryCancel:{
				order_cancel_reason_code:''
			}
		})
		const opt = useRoute().query
		if( opt.id ) getOrderDetail()
		function getOrderDetail(){
			goodsModel.getOrderDetail(opt.id,res=>{
				if( res.order_status == 0 ) _d.active = 1
				if( res.order_status == 1 ) _d.active = 2
				if( res.order_status == 2 ) _d.active = 3
				if( res.order_status == 3 ) _d.active = 4
				_d.detail = res
				_d.copy_text = res.name+' '+res.mobile +' ' +res.address
				if( res.runing &&  res.runing.run_type == 'dada' ){
					if( ['5','9','10','1000'].includes(res.runing.order_status) ){
						_d.show.reback = true  //dada重新下单
					}
				}
			})
		}

		function updateAddress(){
			_d.updateAddressDialog = true
			let { detail } =_d
			let address ={
				address:detail.address,
				name:detail.name,
				mobile:detail.mobile
			}
			_d.address = address
		}
		
		const handleOrder = (type,title)=>goodsModel.handleOrder(opt.id,type,title,()=>getOrderDetail())
		const printOrder = ()=>common.printOrder(opt.id,1)
		return{
			...toRefs(_d),
			getOrderDetail,
			handleOrder,
			updateAddress,
			printOrder
		}
	},
}
</script>

<style lang="scss">
	.el-content{
		overflow: hidden;
	}
	.order-steps{
		width: 80%;
		margin: auto;
	}
	// 订单信息
	.order-info{
		border: 1px solid #f4f4f4;
		width: 90%;
		margin: auto;
		margin-top: 20px;
		font-size: 12px;
		line-height: 30px;
		
		.self-order{
			height: 20px;
			line-height: 20px;
			background: #1489ff;
			color: #fff;
			padding: 0 10px;
			position: absolute;
			margin: -20px 0 0 -20px;
			border-bottom-right-radius: 20px;
		}
		.icon-qrcode{
			font-size: 26px;
			cursor: pointer;
		}
		.order-li{
			display: flex;
			
			.order-title{
				width: 90px;
				color: #868686;
			}
			.el-button--mini{
				height: 28px;
			}
		}
		.order-status{
			color: orange;
			font-size: 20px;
			font-weight: bold;
		}
		.order-tag{
			color: #868686;
		}
	}
	
	// 商品信息
	.goods-info{
		width: 90%;
		margin: auto;
		margin-top: 24px;
		
		.g-info-header{
			font-size: 12px;
			height: 40px;
			line-height: 40px;
			background: #f4f4f4;
			padding: 0 10px;
		}
		.goods-li{
			font-size: 12px;
			border-bottom: 1px solid #F4F4F4;
			.th-cover{
				width: 70px;
				height: 70px;
				margin-top: 15px;
			}
			.goods-name{
				font-size: 12px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 70%;
				margin-left: 10px;
				height: 100%;
				line-height: 100px;
			}
		}
		.li-info{
			font-size: 12px;
			text-align: center;
			line-height: 100px;
			border-right: 1px solid #F4F4F4;
		}
		.total-info{
			width: 100%;
			border: 1px solid #F4F4F4;
			text-align: right;
		}
		.price-inner{
			display: inline-block;
			vertical-align: middle;
			width: 100px;
			text-align: right;
		}
	}
	
	
	//提货码弹框
	.kd-check-order{
		width: 100%;
		height: 340px;
		text-align: center;
		opacity: 0.5;
		.icon-yiwancheng{
			font-size: 100px;
			position: absolute;
		}
		&-title{
			color: #909399;
		}
		&-code{
			font-size: 20px;
			font-weight: bold;
			color: #303133;
			margin: 10px 0;
		}
		&-qrcode{
			width: 100%;
			height: 130px;
			text-align: center;
			margin: 20px 0 0 0;
			img{
				width: 130px;
				height: 100%;
			}
		}
		&-tags{
			color: #C0C4CC;
			font-size: 12px;
			margin: 20px 0 20px 0;
		}
	}
	
	.orderRemark{
		.el-dialog{
			height: 300px;
		}
	}
</style>
